import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || '';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Verkada',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/498d0486-b5d9-4374-9bf0-e296abccef00/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#000',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '',
};
